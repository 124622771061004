import { LoadingIndicator } from '@bp/ui-components/web';
import * as S from './Loader.styles';

interface ILoader {
  color: string;
  testId: string;
}

const Loader = ({ color, testId }: ILoader) => {
  return (
    <S.LoadingWrapper>
      <LoadingIndicator color={color} testID={testId} />
    </S.LoadingWrapper>
  );
};

export default Loader;
