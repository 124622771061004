import React, { useState, createContext, useContext } from 'react';
import { GuestMicrositeAnalyticsEventType, firebaseAnalytics } from '../analytics';
import { logEvent } from 'firebase/analytics';

type AppContextState = {
  chargepointAvailable: boolean;
  starsSelected: number;
};
type AppContextFeedback = {
  question1: any[];
  question2: string;
  submitted: boolean;
};
const appCtxDefaultValue = {
  state: {
    chargepointAvailable: false,
    starsSelected: -1,
  },
  feedback: {
    question1: [] as any,
    question2: '',
    submitted: false,
  },
  orderId: '',
  apolloClient: null,
  setInClientContext: (client: any) => {
    appCtxDefaultValue.apolloClient = client;
  },
  setState: (state: AppContextState) => {
    appCtxDefaultValue.state = state;
  },
  setOrderId: (orderId: string) => {
    appCtxDefaultValue.orderId = orderId;
  },
  setFeedback: (feedback: AppContextFeedback) => {
    appCtxDefaultValue.feedback = feedback;
  },
  onAnalyticsEvent: (_: GuestMicrositeAnalyticsEventType) => {},
};
export const AppContext = createContext(appCtxDefaultValue);

export const useAppContext = () => useContext(AppContext);

export interface IProviderProps {
  children?: React.ReactNode;
  onAnalyticsEvent: (event: GuestMicrositeAnalyticsEventType) => void;
}

export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue;
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    // Save state
    setStoredValue(valueToStore);
    // Save to local storage
    window.localStorage.setItem(key, JSON.stringify(valueToStore));
  };

  return [storedValue, setValue];
}

export const AppProvider = (props: IProviderProps) => {
  const { children } = props;
  const [state, setState] = useState(appCtxDefaultValue.state);
  const [orderId, setOrderId] = useState(appCtxDefaultValue.orderId);
  const [feedback, setFeedback] = useState(appCtxDefaultValue.feedback);
  const [apolloClient, setInClientContext]: any = useState({});

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
        apolloClient,
        setInClientContext,
        feedback,
        setFeedback,
        orderId,
        setOrderId,
        onAnalyticsEvent: (analyticsEvent) => {
          if (firebaseAnalytics) {
            logEvent(firebaseAnalytics, analyticsEvent.eventName, { ...analyticsEvent.payload });
          }
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
