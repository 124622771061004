import { useState, createContext, useContext } from 'react';
import * as tokenMethods from '../utils/auth';
import { IAppConfig, IProviderProps, IUrlQueryParams } from '../common/interfaces';
import { getAppConfigByApiKey } from './appConfigStorage';
import { extractUrlQueryParams } from '../utils/urlQueryHelpers';
import { useHistory } from 'react-router-dom';
import { verifyJWTSession } from '../gateways/services/anonymous.auth';
import { i18n } from 'i18next';
import { CountryLocale } from '../common/enums';

const sessionCtxDefaultValue = {
  jwtToken: '',
  apiKey: '',
  anonUserId: '',
  locale: '',
  countryCode: '',
  preAuthAmount: '',
  themeConfig: '',
  paypalIsEnabled: false,
  cookieConsent: false,
  setCookieConsent: (value: boolean) => {
    sessionCtxDefaultValue.cookieConsent = value;
  },
  setPaymentSession: async (_search: string, _i18n: i18n) => {},
};

const SessionContext = createContext(sessionCtxDefaultValue);

export const useSessionContext = () => useContext(SessionContext);

export const SessionProvider = (props: IProviderProps) => {
  const { children } = props;
  const [anonUserId, setAnonUserId] = useState('');
  const [jwtToken, setJwtToken] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [locale, setLocale] = useState<string>(CountryLocale.EN);
  const [countryCode, setCountryCode] = useState('');
  const [preAuthAmount, setPreAuthAmount] = useState('');
  const [themeConfig, setThemeConfig] = useState('');
  const [paypalIsEnabled, setPaypalIsEnabled] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(sessionCtxDefaultValue.cookieConsent);

  const history = useHistory();

  const loadAppConfigByApiKey = (apiKey: string): IAppConfig | undefined => {
    try {
      const appConfig = getAppConfigByApiKey(apiKey);
      return appConfig as IAppConfig;
    } catch {
      history.push('/not-authorised');
    }
  };

  const extractUrlQueryParamsHandler = (search: string) => {
    try {
      const params = extractUrlQueryParams(search);
      return params as IUrlQueryParams;
    } catch {
      history.push('/not-authorised');
    }
  };

  const setPayPalConfig = (appConfig: IAppConfig) => {
    const paymentEnabled = appConfig?.featureFlags.paypalEnabled ?? false;
    setPaypalIsEnabled(paymentEnabled);
  };

  const setThemeConfigHandler = (appConfig: IAppConfig) => {
    const theme = appConfig?.themeConfig ?? 'bpCore';
    setThemeConfig(theme);
  };

  const setPaymentSession = async (search: string, i18n: i18n) => {
    const params: IUrlQueryParams = extractUrlQueryParamsHandler(search) as IUrlQueryParams;
    const token = params?.jwt || '';
    const apiKey = params?.apiKey ?? '';
    const countrylocale = params?.locale ?? CountryLocale.EN;
    setLocale(countrylocale);
    try {
      const isTokenValid: boolean = await verifyJWTSession(token);
      if (!isTokenValid) {
        history.push('/not-authorised');
      }
    } catch (e) {
      return history.push('/not-authorised');
    }

    i18n.changeLanguage(countrylocale);
    setJwtToken(token);
    tokenMethods.setJwtToken(token);
    setApiKey(apiKey);
    setAnonUserId(params?.anonUserId ?? '');

    const appConfig = loadAppConfigByApiKey(apiKey) as IAppConfig;
    setCountryCode(appConfig.countryCode);
    setPreAuthAmount(appConfig.preAuthAmount);
    setPayPalConfig(appConfig);
    setThemeConfigHandler(appConfig);
  };

  return (
    <SessionContext.Provider
      value={{
        jwtToken,
        anonUserId,
        paypalIsEnabled,
        apiKey,
        locale,
        countryCode,
        preAuthAmount,
        cookieConsent,
        setCookieConsent,
        setPaymentSession,
        themeConfig,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
