import styled from 'styled-components';

export const PaypalLogo = styled.img`
  height: 2rem;
  margin-right: 2px;
`;

interface IPropsLogo {
  margin: number;
}

export const Logos = styled.div<IPropsLogo>`
  margin-left: ${({ margin }) => `${margin}rem`};
  display: flex;
`;
