import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Title = styled.p`
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.bpCore.colors.dark[500]};
  margin: 0 0 1rem 0;
`;

export const HeadingText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.bpCore.colors.neutral[600]};
  font-size: 0.6875rem;
  line-height: 1rem;
`;
