import { gql } from '@apollo/client';

export const START_JOURNEY = gql`
  mutation startJourney($paymentMethodType: String, $userId: String, $appCountry: String) {
    startJourney(
      paymentMethodType: $paymentMethodType
      appCountry: $appCountry
      userId: $userId
      appType: "mobile"
    ) {
      journeyId
      dropInSession {
        dropInType
        sessionType
        merchant
        session {
          id
          updateStatus
          version
        }
        redirectUrl
      }
    }
  }
`;

export const CONTENTFUL_COPY = gql`
  query webshoppage($id: String!, $locale: String) {
    webshoppage(id: $id, locale: $locale) {
      title
      subtitle
      title2
      subtitle2
    }
  }
`;

export const CONTENTFUL_USER_FEEDBACK = gql`
  query webshopuserfeedback($id: String!, $locale: String) {
    webshopuserfeedback(id: $id, locale: $locale) {
      title
      subtitle
      feedbackitem1
    }
  }
`;

export const CONTENTFUL_FOOTER = gql`
  query headerfooter($id: String!, $locale: String) {
    headerfooter(id: $id, locale: $locale) {
      title
      button
      subtitle
    }
  }
`;
