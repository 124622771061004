import { createGlobalStyle } from 'styled-components';
import aralRegular from '../fonts/AralPOS/AralPOS-Regular.ttf';
import aralLight from '../fonts/AralPOS/AralPOS-Light.ttf';
import aralMedium from '../fonts/AralPOS/AralPOS-Medium.ttf';
import aralSemiBold from '../fonts/AralPOS/AralPOS-SemiBold.ttf';
import robotoRegular from '../fonts/Roboto/Roboto-Regular.ttf';
import breakpoints from './breakpoints';

const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'Aral POS';
    font-style: normal;
    font-weight: normal;
    src: url(${aralRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Aral POS';
    font-style: normal;
    font-weight: 200;
    src: url(${aralLight}) format('truetype');
  }

  @font-face {
    font-family: 'Aral POS';
    font-style: normal;
    font-weight: 500;
    src: url(${aralMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Aral POS';
    font-style: normal;
    font-weight: 600;
    src: url(${aralSemiBold}) format('truetype');
  }


  @font-face {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 600;
    src: url(${robotoRegular}) format('truetype');
  }

  html, body {
    font-family: 'Aral POS';
    font-size: 100%; /* 16px base font */
    min-width: ${breakpoints.breakpoints.minWidth};
    overflow-x: hidden;
  }

  body {
    background-color: #d8dbd7;
  }

`;

export default GlobalStyles;
