import PaypalLogo from '../../../assets/Paypal_Logo.png';
import Loader from '../../Atoms/Loader/Loader';
import * as S from './Payment.styles';
import { Button } from '@bp/ui-components/web';

interface IPaypalPaymentOption {
  vm: IPaymentViewModel;
  onPress: Function;
}

interface IPaymentViewModel {
  loadingState: boolean;
  paypalButtonState: boolean;
  loaderColor: string;
}

const PaypalPaymentOption = ({ vm, onPress }: IPaypalPaymentOption) => {
  return (
    <Button
      type="primary"
      disabled={vm.loadingState}
      onPress={onPress}
      testID="addCard-paypalButton"
      color="#FFC43A"
      accessibilityLabel="Paypal"
    >
      {/*
       // @ts-ignore */}
      {vm.paypalButtonState ? (
        <Loader color={vm.loaderColor} testId="addCard-paypalButton-loading" />
      ) : (
        <>
          <S.Logos margin={0}>
            <S.PaypalLogo
              alt="paypal logo"
              src={PaypalLogo}
              data-testid="addCard-cardButton-paypalLogo"
            />
          </S.Logos>
        </>
      )}
    </Button>
  );
};

export default PaypalPaymentOption;
