import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ThemeContext } from 'styled-components';
import { Spacer } from '@bp/ui-components/web';
import { useTranslation } from 'react-i18next';
import MastercardDropIn from 'bppayui-sdk/lib/Mastercard';
import { Environment } from 'bppayui-sdk/lib/Mastercard/types';
import { useSessionId, actionTypes } from '../../../contexts/bppaySession.context';
import { useSessionContext } from '../../../contexts/session.context';
import { PaymentErrorModalSection } from './CardDetails.functions';
import ContentWrapper from '../../Atoms/ContentWrapper/ContentWrapper';
import { START_JOURNEY } from '../../../gateways/graphql/queries';
import PageContent from '../../Atoms/PageContent/PageContent';
import CardPaymentOption from '../../Molecules/PaymentMethod/CardPaymentOption';
import PaypalPaymentOption from '../../Molecules/PaymentMethod/PaypalPaymentOption';
import SingleLineBorder from '../../Atoms/Border/SingleLineBorder';
import { CountryLocale, PaymentModalLocale } from '../../../common/enums';

let bppayEnvironment: Environment;
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  bppayEnvironment = Environment.Production;
} else {
  bppayEnvironment = Environment.Sandbox;
}

const CardDetails = () => {
  const { setPaymentSession, anonUserId, paypalIsEnabled, countryCode, preAuthAmount } =
    useSessionContext();

  const theme = useContext(ThemeContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [dropInSession, dispatch] = useSessionId();
  const [error, setError] = useState(false);

  const [loadingState, setLoadingState] = useState(false);
  const [paypalButtonState, setPaypalButtonState] = useState(false);
  const [cardButtonState, setCardButtonState] = useState(false);

  const gatewayRef = useRef<MastercardDropIn | null>(null);

  const search = useLocation().search;

  useEffect(() => {
    const setSession = async () => {
      await setPaymentSession(search, i18n);
    };
    setSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [startJourney, { data: startJourneyData }] = useMutation(START_JOURNEY, {
    onError: () => {
      setError(true);
    },
  });

  const errorCallback = () => {
    history.push('/service-outage');
  };

  const pay = useCallback(() => {
    // Wait while payment sdk load the Mastercard Script, and attach iframe to body
    // @ts-ignore
    if (!window.Checkout || !gatewayRef.current) {
      setTimeout(() => {
        pay();
      }, 1000);
    } else {
      if (
        process.env.REACT_APP_ENVIRONMENT === 'develop' ||
        process.env.REACT_APP_ENVIRONMENT === 'local'
      ) {
        history.push('/success');
      } else {
        gatewayRef.current.pay();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dropInSession?.journeyId || !startJourneyData) return;

    const config = {
      config: {
        errorCallback,
        successCallbackUrl: '/success',
        targetWindow: window as Window,
        merchantName: t('payment.TITLE'),
        locale: PaymentModalLocale[i18n.language.toUpperCase() as PaymentModalLocale],
      },
      dropInSession,
    };

    gatewayRef.current = new MastercardDropIn(config, bppayEnvironment);

    const cancelCallback = () => {
      setLoadingState(false);
      setCardButtonState(false);
      setPaypalButtonState(false);

      if (dropInSession?.journeyId) {
        dispatch({ type: actionTypes.CLEAR_SESSION_ID });
      }
    };
    document.addEventListener(gatewayRef.current.cancelEventName, () => {
      cancelCallback();
      if (gatewayRef.current) {
        document.removeEventListener(gatewayRef.current.cancelEventName, cancelCallback);
      }
    });

    pay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pay, dropInSession]);

  useEffect(() => {
    if (dropInSession?.journeyId) {
      dispatch({ type: actionTypes.CLEAR_SESSION_ID });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startJourneyData) {
      dispatch({
        type: actionTypes.UPDATE_SESSION_ID,
        payload: startJourneyData.startJourney,
      });
    }
  }, [dispatch, startJourneyData]);

  const PaymentOptions = () => {
    const cardPaymentStateHandler = () => {
      startJourney({
        variables: {
          paymentMethodType: 'card',
          userId: anonUserId,
          appCountry: countryCode,
        },
      });
      setLoadingState(true);
      setCardButtonState(true);
      setPaypalButtonState(false);
    };

    const paypalPaymentStateHandler = () => {
      startJourney({
        variables: {
          paymentMethodType: 'paypal',
          userId: anonUserId,
          appCountry: countryCode,
        },
      });
      setLoadingState(true);
      setCardButtonState(false);
      setPaypalButtonState(true);
    };

    const paymentErrorHandler = () => {
      setLoadingState(false);
      setCardButtonState(false);
      setPaypalButtonState(false);
      setError(false);
    };

    let cardPaymentViewModel = {
      buttonText: t('payment.CARD_BUTTON'),
      loadingState: loadingState,
      cardButtonState: cardButtonState,
      loaderColor: theme.bpCore.colors.white,
    };

    let paypalViewModel = {
      loadingState: loadingState,
      paypalButtonState: paypalButtonState,
      loaderColor: theme.bpCore.colors.white,
    };

    return (
      <ContentWrapper>
        <Spacer vSpace={48} />
        <CardPaymentOption vm={cardPaymentViewModel} onPress={cardPaymentStateHandler} />
        {paypalIsEnabled && (
          <>
            <Spacer vSpace={8} />
            <PaypalPaymentOption vm={paypalViewModel} onPress={paypalPaymentStateHandler} />
          </>
        )}
        <PaymentErrorModalSection visible={error} onPress={paymentErrorHandler} />
        <Spacer vSpace={72} />
      </ContentWrapper>
    );
  };

  const PageHeader = () => {
    let headerViewModel = {
      title: t('payment.HEADING_TITLE'),
      headingText: t('payment.HEADING_TEXT', { preAuthAmount }),
    };

    return <PageContent vm={headerViewModel} />;
  };

  const PageFooter = () => {
    let footerViewModel = {
      title: t('payment.FOOTER_TITLE'),
      headingText: t('payment.FOOTER_TEXT'),
    };

    return countryCode !== CountryLocale.NL ? (
      <>
        <SingleLineBorder />
        <PageContent vm={footerViewModel} />
        <Spacer vSpace={8} />
      </>
    ) : null;
  };

  return (
    <>
      <PageHeader />
      <PaymentOptions />
      <PageFooter />
    </>
  );
};

export default CardDetails;
