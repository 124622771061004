// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactNode } from 'react';
import * as S from './ContentWrapper.styles';

interface ContentWrapperI {
  bgColor?: string;
  vPadding?: string;
  children?: ReactNode | ReactNode[];
}

const ContentWrapper = ({ bgColor, children, vPadding }: ContentWrapperI) => (
  <S.Wrapper bgColor={bgColor} data-testid="contentWrapper-block">
    <S.Content vPadding={vPadding} data-testid="contentWrapper-container">
      {children}
    </S.Content>
  </S.Wrapper>
);

export default ContentWrapper;
