import { GuestMicrositeAnalyticsEventStartChargeScreenSelectPaymentMethodSelectType } from './events/StartChargeScreenSelectPaymentMethodSelect';
import firebaseAnalytics from './firebaseSetup';

export enum GuestMicrositeAnalyticsEvent {
  START_CHARGE_SCREEN_SELECT_PAYMENT_METHOD_SELECT = 'GuestMicrositeAnalyticsEvent.START_CHARGE_SCREEN_SELECT_PAYMENT_METHOD_SELECT',
}
export type GuestMicrositeAnalyticsEventType =
  GuestMicrositeAnalyticsEventStartChargeScreenSelectPaymentMethodSelectType;

export { firebaseAnalytics };
