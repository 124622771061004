import styled from 'styled-components';
import isAndroidOSPlatform from '../../../utils/getPlatform';
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${isAndroidOSPlatform() ? '48' : '0'}px);
  max-width: ${({ theme }) => theme.breakpoints.maxWidth};
  margin: 0 auto;
  background-color: ${({ theme }) => theme.bpCore.colors.white};
`;
export const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
