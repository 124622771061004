import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { AppWrapper } from './components/Templates';
import StyleWrapper from './StyleWrapper';
import Routes from './router/router';
import GraphQLProvider from './gateways/graphql/provider';
import { AppProvider } from './contexts/app.context';
import { SessionProvider } from './contexts/session.context';
import { BpPaySessionProvider } from './contexts/bppaySession.context';

const App = () => {
  return (
    <BpPaySessionProvider>
      <AppProvider onAnalyticsEvent={() => {}}>
        <StyleWrapper>
          <BrowserRouter>
            <CookiesProvider>
              <SessionProvider>
                <GraphQLProvider>
                  <AppWrapper>
                    <Routes />
                  </AppWrapper>
                </GraphQLProvider>
              </SessionProvider>
            </CookiesProvider>
          </BrowserRouter>
        </StyleWrapper>
      </AppProvider>
    </BpPaySessionProvider>
  );
};

export default App;
