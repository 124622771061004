const ukEnglish = {
  footer: {
    TEXT: '© Aral pulse 2020 - All rights reserved',
    LINK: 'Terms and Conditions',
  },
  timeout: {
    HEADING: 'Payment timed out!',
    TEXT: 'Your payment has timed out, please go back and try again, if this problem persists contact us.',
    BUTTON: 'Try again',
  },
  STEPS: ['Enter charger ID', 'Chargepoint information', 'Payment', 'Charging'],
  payment: {
    TITLE: 'Maximum Charge',
    CARD_BUTTON: 'Card',
    HEADING_TITLE: 'Please choose your payment method.',
    HEADING_TEXT:
      'So that you can use the charging point / station, we take a minimum amount of {{preAuthAmount}} as a deposit for authorization. This is offset against the total cost of your charge.',
    FOOTER_TITLE: 'Note about receipts',
    FOOTER_TEXT:
      'At the end of the charging process, you can download the receipt as a PDF or log in to add the charge to your charging history.',
  },
  payment_error: {
    HEADING: 'Payment failed',
    TEXT: "We couldn't credit your deposit.",
    DESCRIPTION:
      'Please check your payment details and try again or choose an alternative payment method.',
    BUTTON: 'Retry payment',
  },
  payment_outage: {
    HEADING: 'Payment Service Outage',
    TEXT: 'We are expecting an issue with our payments service, please try again.',
    BUTTON: 'Refresh',
  },
  notAuthorised: {
    HEADING: 'Not authorised',
    TEXT: 'User not authorised, please go back and try again',
  },
  preAuthorised: {
    CONTINUE: 'Return to bp pulse',
    SUBMITTED: 'Pre-authorisation submitted',
    INSTRUCTION: 'You can now return back to the bp pulse app.',
  },
};
export default ukEnglish;
