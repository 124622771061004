const dutch = {
  footer: {
    TEXT: '© bp pulse 2020 - Alle rechten voorbehouden',
    LINK: 'Algemene voorwaarden',
  },
  timeout: {
    HEADING: 'Betaling mislukt',
    TEXT: 'Je betaling is mislukt. Probeer het nog eens. Als dit probleem zich blijft voordoen, neem dan contact met ons op.',
    BUTTON: 'Probeer opnieuw',
  },
  STEPS: ['Voer charger-id in', 'Laadpunt informatie', 'Betalingsmethode', 'Opladen'],
  payment: {
    TITLE: 'Maximale lading',
    CARD_BUTTON: 'Creditcard',
    HEADING_TITLE: 'Kies jouw betalingsmethode',
    HEADING_TEXT:
      'Wij vragen een minimumbedrag van {{preAuthAmount}},- als borg voor de autorisatie zodat je gebruik kunt maken van het oplaadpunt. Dit bedrag wordt verrekend met de totale kosten van je oplaadbeurt.',
    FOOTER_TITLE: 'Opmerking bij je factuur',
    FOOTER_TEXT:
      'Na de laadsessie kun je de factuur downloaden als PDF of inloggen om de sessie aan jouw laadgeschiedenis toe te voegen.',
  },
  payment_error: {
    HEADING: 'Betaling mislukt',
    TEXT: 'We konden het bedrag niet afschrijven',
    DESCRIPTION:
      'Controleer je betaling en probeer het nog eens of kies een andere betalingsmethode',
    BUTTON: 'Probeer opnieuw te betalen',
  },
  payment_outage: {
    HEADING: 'Storing. Betalen niet mogelijk.',
    TEXT: 'We verwachten een storing. Probeer opnieuw.',
    BUTTON: 'Opnieuw laden',
  },
  notAuthorised: {
    HEADING: 'Niet toegestaan',
    TEXT: 'Niet geautoriseerde gebruiker. Probeer opnieuw.',
  },
  preAuthorised: {
    CONTINUE: 'Terug naar bp pulse',
    SUBMITTED: 'Pre-autorisatie ingediend',
    INSTRUCTION: 'Je kunt nu teruggaan naar de bp pulse app',
  },
};
export default dutch;
