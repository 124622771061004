import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { useSessionContext } from '../../contexts/session.context';

let lastRequestID = 0;
const clientUUID = Math.round(Math.random() * 10000);

const GraphQLProvider = ({ children }: any) => {
  const apiKey = process.env.REACT_APP_API_GATEWAY_KEY || 'local';
  const { jwtToken } = useSessionContext();

  // Handle errors client side
  const errorLink = onError(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }: any) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );
    if (networkError) console.log('[Network Error]: ', networkError);
  });

  // Apply request headers for jaeger tracing and authorization if present
  const headerLink = setContext((_, { operationName, headers }: any) => {
    // Only increment request count if a operation exists
    if (operationName) lastRequestID += 1;
    return {
      headers: {
        ...headers,
        'jaeger-baggage': `session=${clientUUID}, request=${lastRequestID}`,
        jaegersession: clientUUID,
        jaegerrequest: lastRequestID,
      },
    };
  });

  // Create Http link
  const link = createHttpLink({
    uri: process.env.REACT_APP_PRIVATE_GATEWAY_SERVER_HTTP || 'http://localhost:4030',
  });

  // Create an inmemory cache instance for caching graphql data
  const cache = new InMemoryCache();

  const setAuthLink = setContext(async (_, { headers }) => {
    let token = jwtToken;
    const tokenData = JSON.parse(
      Buffer.from(jwtToken.split('.')[1] ?? '', 'base64').toString('utf8'),
    );
    if (!tokenData?.exp || tokenData.exp <= Date.now() / 1000) {
      //token = await tokenMethods.refreshToken();
      throw new Error('Expired Token');
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token,
        'x-api-key': apiKey,
      },
    };
  });

  // Create apollo client
  const client = new ApolloClient({
    link: ApolloLink.from([headerLink, setAuthLink, errorLink, link]),
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
export default GraphQLProvider;
