import { Redirect } from 'react-router-dom';

const PreAuth = () => {
  return (
    <>
      <Redirect to="/success" />
    </>
  );
};
export default PreAuth;
