import * as S from './PageContent.styles';
import ContentWrapper from '../../Atoms/ContentWrapper/ContentWrapper';

interface IHeader {
  title: string;
  headingText: string;
}

interface IHeaderViewModel {
  vm: IHeader;
}

const PageHeader = ({ vm }: IHeaderViewModel) => {
  return (
    <S.Wrapper data-testid="progress">
      <ContentWrapper vPadding="1.5rem">
        <S.Title data-testid="payment-headingtitle">{vm.title}</S.Title>
        <S.HeadingText data-testid="payment-headingtext">{vm.headingText}</S.HeadingText>
      </ContentWrapper>
    </S.Wrapper>
  );
};
export default PageHeader;
