import { useHistory } from 'react-router-dom';
import { Spacer, Button } from '@bp/ui-components/web';
import { useTranslation } from 'react-i18next';
import TimeoutErrorSvg from '../../../assets/time_out_error.svg';
import * as S from './Timeout.styles';
import ContentWrapper from '../../Atoms/ContentWrapper/ContentWrapper';
import { IPageTitle, ITimeoutContentArea } from './interfaces';

const PageTitle = ({ heading }: IPageTitle) => {
  return <S.Title data-testid="timeout-title">{heading}</S.Title>;
};

const TimeoutImage = () => {
  return <S.Image alt="Timeout image" src={TimeoutErrorSvg} data-testid="timeout-image" />;
};

const TimeoutContentArea = ({ timeOutMessage, buttonText }: ITimeoutContentArea) => {
  const history = useHistory();

  const retryHandler = () => {
    history.push('/retry');
  };

  return (
    <ContentWrapper>
      <S.Text data-testid="timeout-text">{timeOutMessage}</S.Text>
      <Spacer vSpace={32} />
      <Button
        type="primary"
        onPress={retryHandler}
        testID="timeout-button"
        accessibilityLabel={buttonText}
      >
        {buttonText}
      </Button>
    </ContentWrapper>
  );
};

export const TimeOutComponent = () => {
  const { t } = useTranslation();

  let viewModel = {
    heading: t('timeout.HEADING'),
    timeOutMessage: t('timeout.TEXT'),
    buttonText: t('timeout.BUTTON'),
  };

  return (
    <div>
      <PageTitle heading={viewModel.heading} />
      <Spacer vSpace={16} />
      <TimeoutImage />
      <Spacer vSpace={32} />
      <TimeoutContentArea
        timeOutMessage={viewModel.timeOutMessage}
        buttonText={viewModel.buttonText}
      />
      <Spacer vSpace={64} />
    </div>
  );
};
