const german = {
  footer: {
    TEXT: '© Aral pulse 2020 - Alle Rechte vorbehalten.',
    LINK: 'Allgemeine Geschäftsbedingungen.',
  },
  timeout: {
    HEADING: 'Die Zahlung wurde abgebrochen.',
    TEXT: 'Aufgrund einer Zeitüberschreitung konnte deine Zahlung nicht ausgeführt werden. Bitte versuche es erneut. Sollte es wiederholt zu Problemen kommen, wende dich bitte direkt an uns.',
    BUTTON: 'Erneut versuchen',
  },
  STEPS: [
    'Nummer (EVSE-ID) der Ladesäule eingeben',
    'Informationen zur Ladesäule',
    'Zahlung',
    'Laden',
  ],
  payment: {
    TITLE: 'Maximale Ladung',
    CARD_BUTTON: 'Kreditkarte',
    HEADING_TITLE: 'Bitte wähle deine Zahlungsmethode.',
    HEADING_TEXT:
      'Damit du den Ladevorgang starten kannst, erheben wir eine Vorautorisierung in Höhe von {{preAuthAmount}}. Wir buchen am Ende des Ladevorgangs nur den tatsächlichen Endbetrag ab und geben den Restbetrag der Vorautorisierung wieder frei.',
    FOOTER_TITLE: 'Hinweis zu deinem Beleg',
    FOOTER_TEXT: 'Am Ende des Ladevorgangs kannst du den Belag als PDF herunterladen.',
  },
  payment_error: {
    HEADING: 'Zahlung fehlgeschlagen',
    TEXT: 'Wir konnten deine Kaution nicht gutschreiben.',
    DESCRIPTION:
      'Bitte überprüfe deine Zahlungsdetails und versuche es erneut oder wähle eine alternative Zahhlungsmethode.',
    BUTTON: 'Zahlung wiederholen',
  },
  payment_outage: {
    HEADING: 'Ausfall des Zahlungsdienstes',
    TEXT: 'Bei unserem Service ist ein Problem aufgetreten, bitte versuche es erneut.',
    BUTTON: 'Aktualisieren',
  },
  notAuthorised: {
    HEADING: 'Nicht autorisiert',
    TEXT: 'Benutzer nicht autorisiert, bitte gehe zurück und versuche es erneut.',
  },
  preAuthorised: {
    CONTINUE: 'Zurück zu meinAral',
    SUBMITTED: 'Vorautorisierung übermittelt',
    INSTRUCTION: 'Du kannst jetzt zur meinAral App zurückkehren.',
  },
};
export default german;
