import VisaLogo from '../../../assets/Visa_Logo.png';
import AmexLogo from '../../../assets/Amex_Logo.png';
import MastercardLogo from '../../../assets/Mastercard_Logo.png';

import CardLogo from '../../Atoms/Logo/CardLogo';
import * as S from './Payment.styles';
import Loader from '../../Atoms/Loader/Loader';
import AnalyticsButton from '../../Atoms/AnalyticsButton/AnalyticsButton';
import { GuestMicrositeAnalyticsEventStartChargeScreenSelectPaymentMethodSelect } from '../../../analytics/events/StartChargeScreenSelectPaymentMethodSelect';

interface ICardPaymentOption {
  vm: ICardPaymentViewModel;
  onPress: Function;
}

interface ICardPaymentViewModel {
  buttonText: string;
  loadingState: boolean;
  cardButtonState: boolean;
  loaderColor: string;
}

const CardPaymentOption = ({ vm, onPress }: ICardPaymentOption) => {
  return (
    <AnalyticsButton
      analyticsEvent={GuestMicrositeAnalyticsEventStartChargeScreenSelectPaymentMethodSelect({})}
      type="primary"
      disabled={vm.loadingState}
      onPress={onPress}
      testID="addCard-cardButton"
    >
      {/*
     // @ts-ignore */}
      {vm.cardButtonState ? (
        <Loader color={vm.loaderColor} testId="addCard-cardButton-loading" />
      ) : (
        <>
          {vm.buttonText}
          <S.Logos margin={1}>
            <CardLogo alt="visa logo" src={VisaLogo} testId="addCard-cardButton-visaLogo" />
            <CardLogo alt="amex logo" src={AmexLogo} testId="addCard-cardButton-amexLogo" />
            <CardLogo
              alt="mastercard logo"
              src={MastercardLogo}
              testId="addCard-cardButton-mastercardLogo"
            />
          </S.Logos>
        </>
      )}
    </AnalyticsButton>
  );
};

export default CardPaymentOption;
