import { Spacer, Button } from '@bp/ui-components/web';
import { useTranslation } from 'react-i18next';
import PaymentServiceOutageErrorSvg from '../../../assets/time_out_error.svg';
import * as S from './PaymentServiceOutage.styles';
import ContentWrapper from '../../Atoms/ContentWrapper/ContentWrapper';
import { IPageTitle, IPaymentServiceOutageContentArea } from './interfaces';
import { useSessionContext } from '../../../contexts/session.context';

const PageTitle = ({ heading }: IPageTitle) => {
  return <S.Title data-testid="payment-service-outage-title">{heading}</S.Title>;
};

const PaymentServiceOutageImage = () => {
  return (
    <S.Image
      alt="Payment Service Down"
      src={PaymentServiceOutageErrorSvg}
      data-testid="payment-service-outage-image"
    />
  );
};

const PaymentServiceOutageContentArea = ({
  message,
  buttonText,
}: IPaymentServiceOutageContentArea) => {
  const { jwtToken: jwt, anonUserId: userid, apiKey: key } = useSessionContext();
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const refreshHandler = () => {
    const urlParams = new URLSearchParams({
      jwt,
      userid,
      key,
      locale,
    }).toString();

    window.location.assign(`/?${urlParams}`);
  };

  return (
    <ContentWrapper>
      <S.Text data-testid="payment-service-outage-text">{message}</S.Text>
      <Spacer vSpace={32} />
      <Button
        type="primary"
        onPress={refreshHandler}
        testID="payment-service-outage-button"
        accessibilityLabel={buttonText}
      >
        {buttonText}
      </Button>
    </ContentWrapper>
  );
};

export const PaymentServiceOutageComponent = () => {
  const { t } = useTranslation();

  let viewModel = {
    heading: t('payment_outage.HEADING'),
    message: t('payment_outage.TEXT'),
    buttonText: t('payment_outage.BUTTON'),
  };

  return (
    <div>
      <PageTitle heading={viewModel.heading} />
      <Spacer vSpace={16} />
      <PaymentServiceOutageImage />
      <Spacer vSpace={32} />
      <PaymentServiceOutageContentArea
        message={viewModel.message}
        buttonText={viewModel.buttonText}
      />
      <Spacer vSpace={64} />
    </div>
  );
};
