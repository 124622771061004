import * as S from './AppWrapper.styles';

interface IProps {
  children: React.ReactNode;
  cookiesDisabled?: boolean;
}
const AppWrapper = (props: IProps) => {
  const { children } = props;
  return (
    <S.Wrapper>
      <S.ContentWrapper>{children}</S.ContentWrapper>
    </S.Wrapper>
  );
};

export default AppWrapper;
