import { AppCountries } from '../common/enums';
import { IAppConfig } from '../common/interfaces';

const appConfig = [
  {
    apiKey: process.env.REACT_APP_API_KEY_UK_APP_CONFIG,
    appName: 'UKApp',
    preAuthAmount: '60 €',
    countryCode: AppCountries.UK,
    featureFlags: {
      cardEnabled: true,
      paypalEnabled: true,
    },
  },
  {
    apiKey: process.env.REACT_APP_API_KEY_NL_APP_CONFIG,
    appName: 'NLApp',
    preAuthAmount: '69 €',
    countryCode: AppCountries.NL,
    featureFlags: {
      cardEnabled: true,
      paypalEnabled: true,
    },
  },
  {
    apiKey: process.env.REACT_APP_API_KEY_ARAL_APP_CONFIG,
    appName: 'AralApp',
    preAuthAmount: '69 €',
    countryCode: AppCountries.DE,
    themeConfig: 'aral',
    featureFlags: {
      cardEnabled: true,
      paypalEnabled: true,
    },
  },
];

export const getAppConfigByApiKey = (apiKey: string): IAppConfig => {
  const config = appConfig.find((x) => x.apiKey === apiKey);
  if (config === undefined) throw new Error('Invalid ApiKey');

  return config as IAppConfig;
};
