import { IUrlQueryParams } from '../common/interfaces';
import * as tokenMethods from '../utils/auth';

export const extractUrlQueryParams = (search: string): IUrlQueryParams => {
  const tokenParam = new URLSearchParams(search).get('jwt');

  if (tokenParam === null || tokenParam === '') throw new Error('token is missing');

  const apiParam = new URLSearchParams(search).get('key');

  if (apiParam === null) throw new Error('api key is missing');

  let anonUserId = new URLSearchParams(search).get('userid');
  if (anonUserId === null || anonUserId === '') {
    anonUserId = tokenMethods.getDecodedUserId(tokenParam);
  }

  let locale = new URLSearchParams(search).get('locale');
  if (locale === null || locale === '') {
    locale = 'de';
  }

  return {
    jwt: tokenParam ?? '',
    apiKey: apiParam ?? '',
    anonUserId: anonUserId ?? '',
    locale,
  };
};
