import { ThemeProvider } from '@bp/ui-components/web';
import { ThemeProvider as WebProvider } from 'styled-components';
import { theme as bpCore } from '@bp/ui-components/web/core';
import { theme as bpPulseOverride } from '@bp/ui-components/web/core/themes/pulse';
import GlobalStyles from './utils/GlobalStyles';
import defaultsDeep from 'lodash.defaultsdeep';
import micrositeTheme from './theme/Theme.default';
import { useSessionContext } from './contexts/session.context';

const StyleWrapper: React.FC = ({ children }) => {
  const siteTheme =
    useSessionContext().themeConfig === 'aral'
      ? defaultsDeep(micrositeTheme, bpCore)
      : defaultsDeep(micrositeTheme, bpPulseOverride, bpCore);

  return (
    <ThemeProvider customTheme={siteTheme}>
      <WebProvider theme={siteTheme}>
        <GlobalStyles />
        <>{children}</>
      </WebProvider>
    </ThemeProvider>
  );
};

export default StyleWrapper;
