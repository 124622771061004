import { GuestMicrositeAnalyticsEvent } from '../index';

type GuestMicrositeAnalyticsEventStartChargeScreenSelectPaymentMethodSelectPayload = {};

export type GuestMicrositeAnalyticsEventStartChargeScreenSelectPaymentMethodSelectType = {
  type: GuestMicrositeAnalyticsEvent.START_CHARGE_SCREEN_SELECT_PAYMENT_METHOD_SELECT;
  payload: GuestMicrositeAnalyticsEventStartChargeScreenSelectPaymentMethodSelectPayload;
  eventName: string;
};

export const GuestMicrositeAnalyticsEventStartChargeScreenSelectPaymentMethodSelect = (
  payload: GuestMicrositeAnalyticsEventStartChargeScreenSelectPaymentMethodSelectPayload,
): GuestMicrositeAnalyticsEventStartChargeScreenSelectPaymentMethodSelectType => ({
  type: GuestMicrositeAnalyticsEvent.START_CHARGE_SCREEN_SELECT_PAYMENT_METHOD_SELECT,
  payload,
  eventName: 'Guest_StartChargeScreen_SelectPaymentMethod_Select',
});
