import jwt from 'jsonwebtoken';

let token = '';

export const setJwtToken = (jwt: string) => {
  token = jwt;
  return token;
};

export const getDecodedUserId = (token: string): string => {
  try {
    const { anonUserId: userId }: any = jwt.decode(token);
    return userId;
  } catch {
    return 'missing placeholder';
  }
};
