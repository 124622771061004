import { useTranslation } from 'react-i18next';
import { Spacer } from '@bp/ui-components/web';

import TimeoutErrorSvg from '../../../assets/time_out_error.svg';
import * as S from './NotAuthorised.styles';

const NotAuthorised = () => {
  const { t } = useTranslation();

  return (
    <>
      <S.Title>{t('notAuthorised.HEADING')}</S.Title>
      <Spacer vSpace={16} />
      <S.Image alt="Error image" src={TimeoutErrorSvg} data-testid="error-image" />
      <Spacer vSpace={32} />
      <S.Text data-testid="error-text">{t('notAuthorised.TEXT')}</S.Text>
      <Spacer vSpace={64} />
    </>
  );
};
export default NotAuthorised;
