import axios from 'axios';

const authUrl = process.env.REACT_APP_AUTH_SERVER_URL || 'http://localhost:4015/api';

const apiKey = process.env.REACT_APP_AUTH_SERVER_API_KEY || 'local';

// Verify if token is in current session.
export const verifyJWTSession = async (token: string) => {
  const data = {
    data: {
      token,
    },
  };
  return await axios
    .post(`${authUrl}/validtoken`, data, {
      headers: { 'x-api-key': apiKey },
    })
    .then((response: any) => response.data?.status)
    .catch((e: any) => {
      throw e;
    });
};
