import * as S from './CardLogo.styles';

interface ICardLogo {
  alt: string;
  src: string;
  testId: string;
}

const CardLogo = ({ alt, src, testId }: ICardLogo) => {
  return <S.Logo alt={alt} src={src} data-testid={testId} />;
};

export default CardLogo;
