import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

export const Header = styled.p`
  font-size: 20px;
  text-align: center;
  color: #111111;
  margin-bottom: 21px;
`;

export const Instruction = styled.p`
  text-align: center;
  color: #111111cc;
  margin-bottom: 43px;
`;
