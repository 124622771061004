import styled from 'styled-components';

export const Wrapper = styled.div<{ bgColor?: string }>`
  ${({ bgColor }) => `background:  ${bgColor};`}
  width: 100%;
  flex-grow: 1;
`;

export const Content = styled.div<{ vPadding?: string }>`
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.minWidth};
  margin: 0 auto;
  padding: ${({ vPadding = 0 }) => vPadding} 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;
