import { useTranslation } from 'react-i18next';
import { BottomTrayModal, BottomTrayModalType } from '@bp/ui-components/web/core';

import breakpoints from '../../../utils/breakpoints';

interface IProps {
  visible: boolean;
  onPress: () => void;
}

const PaymentErrorModal = ({ visible, onPress }: IProps) => {
  const { t } = useTranslation();
  return (
    <BottomTrayModal
      visible={visible}
      type={BottomTrayModalType.ERROR}
      title={t('payment_error.HEADING')}
      message={t('payment_error.TEXT')}
      description={t('payment_error.DESCRIPTION')}
      onBtnPress={onPress}
      btnTitle={t('payment_error.BUTTON')}
      wrapperStyles={{
        maxWidth: breakpoints.breakpoints.maxWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    />
  );
};

export default PaymentErrorModal;
