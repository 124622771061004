import { Button } from '@bp/ui-components/web';
import { useTranslation } from 'react-i18next';

import * as S from './AndroidRedirect.styles';
interface IProps {
  onPress: () => void;
}

const AndroidRedirectButton = ({ onPress }: IProps) => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Header>{t('preAuthorised.SUBMITTED')}</S.Header>
      <S.Instruction>{t('preAuthorised.INSTRUCTION')}</S.Instruction>
      <Button
        type="primary"
        onPress={onPress}
        testID="android-redirectButton"
        color="#000096"
        accessibilityLabel={t('preAuthorised.CONTINUE')}
      >
        {t('preAuthorised.CONTINUE')}
      </Button>
    </S.Container>
  );
};

export default AndroidRedirectButton;
