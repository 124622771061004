// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DropInSession } from 'bppayui-sdk/lib/Mastercard/types';
import { createContext, useContext, useReducer } from 'react';

const initialState: DropInSession & { journeyId: string } = {
  dropInType: '',
  merchant: '',
  session: {
    id: '',
    updateStatus: '',
    version: '',
  },
  sessionType: '',
  journeyId: '',
};

const paySession = 'pay-session';

export const actionTypes = {
  UPDATE_SESSION_ID: 'UPDATE_SESSION_ID',
  CLEAR_SESSION_ID: 'CLEAR_SESSION_ID',
};

const updateSessionId = (payload: { journeyId: string; dropInSession: DropInSession }) => {
  const { dropInSession, journeyId } = payload;
  return {
    journeyId,
    ...dropInSession,
  };
};

const clearSessionId = () => {
  localStorage.removeItem(paySession);

  return initialState;
};

const actions = {
  [actionTypes.UPDATE_SESSION_ID]: updateSessionId,
  [actionTypes.CLEAR_SESSION_ID]: clearSessionId,
};

const reducer = (state: any, action: any) => {
  const { type, payload } = action;

  const stateFn = actions[type];

  const newState = stateFn ? stateFn(payload) : state;

  if (newState?.journeyId?.length > 0) {
    localStorage.setItem(paySession, JSON.stringify(newState));
  }

  return newState;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const SessionContext = createContext([initialState as any, () => {}]);

export const BpPaySessionProvider = ({ children }: any) => {
  const existingState = localStorage.getItem(paySession) || '';
  const state = existingState.length > 0 ? JSON.parse(existingState) : initialState;
  const value = useReducer(reducer, state);

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export const useSessionId = () => {
  return useContext(SessionContext);
};
