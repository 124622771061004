import { Analytics, getAnalytics, isSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GF_API_KEY,
  authDomain: process.env.REACT_APP_GF_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_GF_PROJECT_ID,
  storageBucket: process.env.REACT_APP_GF_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_GF_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_GF_APP_ID,
  measurementId: process.env.REACT_APP_GF_MEASUREMENT_ID,
};

let app = null;
let firebaseAnalytics: Analytics | undefined = undefined;

isSupported().then((result) => {
  if (result) {
    app = initializeApp(firebaseConfig);
    firebaseAnalytics = getAnalytics(app);
  }
});

export { firebaseAnalytics };
