import { Button } from '@bp/ui-components/web';
import { useAppContext } from '../../../contexts/app.context';
import { GuestMicrositeAnalyticsEventType } from '../../../analytics';

interface IButtonProps {
  analyticsEvent: GuestMicrositeAnalyticsEventType;
  onPress: Function;
  children: string;
  [x: string]: any;
}

const AnalyticsButton = ({ analyticsEvent, onPress, ...props }: IButtonProps) => {
  const { onAnalyticsEvent } = useAppContext();
  const handlePress = () => {
    if (analyticsEvent) onAnalyticsEvent(analyticsEvent);
    if (onPress) onPress();
  };

  return (
    <Button onPress={handlePress} {...props}>
      {props?.children}
    </Button>
  );
};

export default AnalyticsButton;
