import styled from 'styled-components';

export const Image = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.bpCore.colors.primary[500]};
  text-align: center;
  margin: 0;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.bpCore.colors.primary[500]};
  font-size: 1.5rem;
  text-align: center;
`;
