export enum CountryLocale {
  EN = 'EN',
  NL = 'NL',
  DE = 'DE',
}

export enum AppCountries {
  UK = 'UK',
  NL = 'NL',
  DE = 'DE',
}

export enum PaymentModalLocale {
  EN = 'EN',
  NL = 'NL',
  DE = 'DE',
}
