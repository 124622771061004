import { React } from 'react';
import { Switch, Route } from 'react-router-dom';
import CardDetails from '../components/Pages/CardDetails/CardDetails';
import Timeout from '../components/Pages/Timeout/Timeout';
import NotAuthorised from '../components/Pages/NotAuthorised/NotAuthorised';
import PreAuth from '../components/Pages/PreAuth/PreAuth';
import PaymentServiceOutage from '../components/Pages/PaymentServiceOutage/PaymentServiceOutage';
import AndroidRedirectButton from '../components/Molecules/AndroidRedirect/AndroidRedirectButton';
import isAndroidOSPlatform from '../utils/getPlatform';

const chargeMfeUrl = 'bppay://charge';

const Routes = () => {
  // load app config get success, retry, close paths
  return (
    <Switch>
      <Route exact path="/" component={CardDetails} />
      <Route exact path="/timeout" component={Timeout} />
      <Route exact path="/service-outage" component={PaymentServiceOutage} />
      <Route exact path="/not-authorised" component={NotAuthorised} />
      <Route exact path="/preauth" component={PreAuth} />
      <Route
        exact
        path="/retry"
        component={() => {
          window.location.replace('bppay://home');
          return null;
        }}
      />
      <Route
        exact
        path="/success"
        component={() => {
          window.location.replace(chargeMfeUrl);
          if (isAndroidOSPlatform) {
            return <AndroidRedirectButton onPress={() => window.location.replace(chargeMfeUrl)} />;
          }
        }}
      />
    </Switch>
  );
};

export default Routes;
